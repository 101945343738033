@import "../../_theme_variables";
/*******************************************************************
					Cart Block Styles
********************************************************************/
#header .cartBox { clear:both;}
#header .shopping_cart {
	position: relative;
	> a:first-child {
		&:after {
			content: "\e071";
			font-family: $flat-icon;
			position:absolute;
			right:0;
			top:0;
			height:40px;
			width:47px;
			font-size:24px;
			line-height:40px;
			display:block;
			color:#aeaeae;
			cursor:pointer;
			@include transition (.3s);
		}
	}
	&:hover, &.activeBox {
		> a:first-child {
			color:#fff;
			b, &:before { color:#888;}
			&:after {
				color:#fff;
			}
		}
	}
	&.activeBox {
		> a:first-child {
			&:after {
				content: "\e073";
			}
		}
	}
}
.shopping_cart {
	width: 100%;
	> a:first-child {
		padding: 8px 50px 9px 15px;
		background: $dark-background;
		display: block;
		position:relative;
		@include transition (.3s);
		color:#888;
		@media (max-width: $screen-md-max) { // max 1199px
			padding-left:15px;
		}
		b {
			color: $light-text-color;
			font: normal 16px/22px $font-custom;
			padding-right: 10px;
			text-transform:uppercase;
			@include transition (.3s);
		}
		&:before {
			content: "\e14d";
			font-family: $flat-icon;
			display: inline-block;
			font-size: 24px;
			color: $light-text-color;
			padding-right: 9px;
			vertical-align:-4px;
			@include transition (.3s);
		}
	}
	.ajax_cart_total {
		display: none !important;
	}
	.block_cart_expand, .block_cart_collapse {
		&:after {
			content: "\f0d7";
			font-family: $font-icon;
			display: inline-block;
			float: right;
			font-size: 18px;
			color: #686666;
			padding: 6px 0 0 0;
		}
	}
	.block_cart_collapse {
		&:after {
			content: "\f0d8";
			padding: 4px 0 2px 0;
		}
	}
}

.cart_block {
	
	.cart_block_list {
		.remove_link {
			position: absolute;
			right: 20px;
			top: 19px;
		}
		.remove_link a,
		.ajax_cart_block_remove_link {
			color: $link-color;
			display: block;
			width: 100%;
			height: 100%;
			&:before {
				display: inline-block;
				content: "\e054";
				font-family: $flat-icon;
				font-size: 18px;
				line-height: 18px;
			}
			&:hover {
				color: $link-hover-color;
			}
		}
	}
	.cart-images {
		float: left;
		margin-right: 20px;
		@include product-image;
		img {
			max-width:100%;
			height:auto;	
		}
	}
	.cart-info {
		overflow: hidden;
		position: relative;
		padding-right: 30px;
		.product-name {
			padding-bottom: 5px;
			a {
				font-size: 13px;
				line-height: 18px;
				display: inline-block;
			}
		}
		.quantity-formated {
			display: inline-block;
			color: #9c9b9b;
			text-transform: uppercase;
			font-size: 10px;
    		padding-right: 5px;
    		.quantity{
    			font-size: 15px;
    		}
		}
	}
	dt {
		font-weight: normal;
		overflow: hidden;
		padding: 20px 10px 16px 20px;
		position: relative;
	}
	dd {
		position: relative;
		.cart_block_customizations{
			border-bottom: 1px dashed #f2f2f2;
			li {
				padding: 10px 20px;
				
				.deleteCustomizableProduct {
					position: absolute;
					right: 20px;	
				}
			}
		}
		
	}
	.cart_block_no_products {
		margin: 0;
		padding: 10px 20px;
	}
	.cart-prices {
		padding: 10px 20px 22px 20px;
		.cart-prices-line {
			overflow: hidden;
			border-bottom: $main_border;
			padding: 7px 0;
			&.last-line {
				border: none;
			}
		}
	}
	.cart-buttons {
		overflow: hidden;
		padding: 20px 20px 10px 20px;
		margin: 0;
		background: none;
		.btn {
			margin-bottom: 10px;
		}
	}
}
/*******************************************************************
					Cart Block(Header) Styles
********************************************************************/
#header {
	.cart_block {
		z-index: 100;
		display: none;
		height: auto;
		background: none;
		border-bottom:$main_border;
		width: 100%;
		.cart_block_list {
			.remove_link a,
			.ajax_cart_block_remove_link {
				color: #333;
				outline: none;
				text-decoration: none;
				&:hover {
					color: #000;
				}	
			}
		}
		.product-atributes {
			padding-bottom: 10px;
			font-size: 11px;
		}
		.cart-prices {
			border: none;
			background: #f2f2f2;
		}
	}
	.block_content {
		margin-bottom: 0;	
	}
}

.cart_block #cart_block_summary,
.cart_block .title_block span,
#header .cart_block h4,
#header .cart_block .title_block,
#header .cart_block h4 {
	display: none
}

.cart_block .cart_block_shipping_cost,
.cart_block .cart_block_tax_cost,
.cart_block .cart_block_total,
.cart_block .cart_block_wrapping_cost {
	float: right
}

.cart_block table.vouchers {
	clear: both;
	width: 80%;
	margin: 0 auto;
	margin-bottom: 10px;
	tr td {
		padding: 2px;
		&.quantity{
			margin-right: 5px;
			min-width: 18px
		}
		&.delete{
			padding-left: 0;
			padding-right: 0;
			text-align: right;
			width: 15px;
		}
	}
}
/*****************************************************************************
					Layer Cart Css
*****************************************************************************/
.layer_cart_overlay {
	background-color: #000;
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 500;
	@include opacity(.20);
}

* html .layer_cart_overlay {
	position: absolute;
	left: 0;
	margin-left: -160px;
	@include opacity(.20)
}

#layer_cart {
	background-color: $light-background;
	position: absolute;
	display: none;
	z-index: 501;
	width: 64%;
	margin-right: 28%;

	@media (min-width: $screen-md) { // min 992px
		width: 64%;
		margin-left: 28%;
	}

	@media (min-width: $screen-md) and (max-width: $screen-md-max) { // min 992px max 1199px

		margin-left: 325px;
		width: 84%;
		min-height: 430px;
	}

	@include border-radius(4px);
	.container_cart {
	}

	margin-top:30px;
	.layer_cart_product {
		padding: 30px 30px 30px 30px;
		overflow: hidden;
		position: static;
		h2 {
			font: normal 20px/26px $font-custom;
			text-transform:uppercase;
			color: #000;
			margin: 0 0 22px;
			padding-right: 100px;
			@media (max-width: $screen-xs-max) { // max 767px
				font-size: 18px;
				padding-right: 0;
				line-height: normal;
			}
			i {
				font-size: 30px;
				line-height: 30px;
				float: left;
				padding-right: 8px;
				@media (max-width: $screen-xs-max) { // max 767px
					font-size: 22px;
					line-height: 22px;
				}
			}
		}
		.product-image-container {
			@include product-image;
			padding: 5px;
			float: left;
			max-width: 178px;
			margin-right: 30px;
			@media (max-width: $screen-xs) { // max 480px
				float: none;
				margin-right: 0;
				margin-bottom: 10px;	
			}
		}
		.layer_cart_product_info {
			padding: 38px 0 0 0;
			#layer_cart_product_title {
				display: block;
				padding-bottom: 8px;
			}
			> div {
				padding-bottom: 7px;
				strong {
					padding-right: 3px
				}
			}
		}
	}
	.layer_cart_cart {
		background: #fafafa;
		border-left: 1px solid $base-border-color;
		padding: 21px 30px 20px 30px;
		@include border-radius(0 4px 4px 0);
		position: relative;
		@media (min-width: $screen-lg) { // min 1200px
			min-height: 400px;
		}
		
		@media (min-width: $screen-md) and (max-width: $screen-md-max) { // min 992px max 1199px
			min-height: 430px;
		}

		@media (max-width: $screen-sm-max) { // max 991px
			border-left: none;
			border-top: 1px solid $base-border-color;
		}
		@media (min-width: $screen-md) { // min 992px
			padding-bottom:170px;
		}
		h2 {
			font: 400 23px/29px $font-family;
			color: $base-text-color;
			border-bottom: 1px solid $base-border-color;	
			padding-bottom: 13px;
			margin: 0 0 17px;
			@media (max-width: $screen-xs-max) { // max 767px
				font-size: 18px;
			}
		}
		.layer_cart_row {
			padding: 0 0 7px 0;
			span {
				font: normal 16px/22px $font-custom;
				margin-left:5px;
				display:inline-block;
				&.ajax_block_cart_total {
					color:#F00;	
				}
			}
		}
		.button-container {
			padding-top:20px;
			@media (min-width: $screen-md) { // min 992px
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				padding: 0 30px 20px 30px;
			}
			.btn {
				margin-bottom: 10px;
			}
			span.btn-md {
				margin-right: 5px;
			}
		}
	}
	.cross {
		position: absolute;
		right: 7px;
		top: 8px;
		width: 25px;
		height: 25px;
		cursor: pointer;
		color: $base-text-color;
		z-index: 2;
		&:before {
			content: "\f057";
			display: block;
			font-family: $font-icon;
			font-size: 25px;
			line-height: 25px;
		}
		&:hover {
			color: $link-color;	
		}
	}
	p {
		padding: 0;
	}
}
/************************************************************************
						Layer Cart Caroucel
*************************************************************************/
#layer_cart {
	.crossseling {
		.crossseling-content {
			border-top: 1px solid $base-border-color;
			padding: 26px 30px 40px 30px;
			position: relative;
		}
		h2 {
			font: 400 23px/26px $font-family;
			color: $base-text-color;
			margin-bottom: 25px;
		}
		#blockcart_list {
			max-width: 773px;
			width: 84%;
			margin: 0 auto;
			overflow: hidden;
			ul {
				display: block;
				li {
					float: left;
					width: 178px;
					margin: 0 0 0 0;
					.product-image-container {
						border: 1px solid $base-border-color;
						text-align: center;
						padding: 5px;
						margin-bottom: 15px;
						
						img {
							width: 100%;
						}	
					}
					.product-name {
						padding-bottom: 5px;
						
						a {
							font-size: 15px;
							line-height: 20px;
						}
					}
				}
			}
			a.bx-prev,
			a.bx-next {
				display: block;
				font-family: $font-icon;
				font-size: 20px;
				line-height: 20px;
				width: 20px;
				height: 20px;
				color: $base-text-color;
				text-indent: -5000px;
				position: absolute;
				top: 45%;
				margin-top: -20px;
				&:after {
					display: block;
					text-indent: 0;
				}
				&:hover {
					color: #c0c0c0;
				}
				&.disabled {
					display: none;
				}
			}
			a.bx-prev {
				left: 5%;
				&:after {
					content: "\f137";
				}
			}
			a.bx-next {
				right: 5%;
				&:after {
					content: "\f138";
				}
			}
			.bx-controls {
				overflow: hidden;
			}
		}
	}
}